@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

// Override default variables before the import
$body-bg: $black;
$body-color: $gray-100;
$primary: $gray-700;
$secondary: $gray-300;

$theme-colors: map-merge(
	$theme-colors,
	(
		'primary': $primary,
		'secondary': $secondary
	)
);


$font-family-base: 'mendl-serif-dusk';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
